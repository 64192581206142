<template>
  <div class="cols-flex">
    <template v-for="item in columns">
      <a-form-model-item
        v-bind="item.itemConfig"
        :key="item.itemConfig.prop"
        v-if="!item.hidden"
        :props="item.itemConfig.prop"
        :style="{
          width: item.itemConfig.width || '50%',
          display: item.itemConfig.hidden || 'block',
        }"
      >
        <slot v-if="item.slot" :name="item.itemConfig.prop"></slot>
        <component
          v-else
          :is="componentsMap[item.type]"
          :placeholder="item.placeholder ? item.placeholder : ''"
          v-model="formState[item.itemConfig.prop]"
          v-bind="item.innerConfig"
          v-on="
            item.innerConfig && item.innerConfig.events
              ? item.innerConfig.events
              : {}
          "
        >
          <template
            v-if="item.innerConfig && item.innerConfig.slot"
            v-slot:[item.innerConfig.slot]
          >
            <slot
              :name="item.itemConfig.prop + '_' + item.innerConfig.slot"
            ></slot>
          </template>
        </component>
      </a-form-model-item>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';

const componentsMap = {
  input: 'a-input',
  radio: 'a-radio-group',
  select: 'a-select',
  switch: 'a-switch',
  checkbox: 'a-checkbox-group',
  date: 'a-date-picker',
  textarea: 'a-textarea',
  password: 'a-input-password',
  upload: 'a-upload',
  number: 'a-input-number',
};

const valueTypeMap = {
  switch: 'checked',
  upload: 'file-list',
};

export default Vue.extend({
  name: 'group-form-config',
  props: {
    config: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: undefined,
    },
    formAttrs: {
      type: Object,
      default: () => ({}),
    },
    modelAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      componentsMap,
    };
  },
  computed: {
    col() {
      const { labelWidth = 80 } = this.formAttrs;
      return {
        labelCol: {
          span: 1,
          style: { width: labelWidth + 'px' },
        },
        wrapperCol: {
          span: 1,
          style: { width: `calc(100% - ${labelWidth}px)` },
        },
      };
    },
    columns() {
      return this.config.map((cfg) => ({
        ...cfg,
        valueType: cfg.valueType || valueTypeMap[cfg.type] || 'value',
      }));
    },
    formState: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
    formVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
});
</script>

<style lang="less" scoped>
.cols-flex {
  display: flex;
  flex-wrap: wrap;
  padding: 34px 30px 15px 0;
}
.cols-flex:nth-last-of-type(1) {
  padding: 34px 30px 50px 0;
}
</style>
