/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-10-14 13:55:49
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-16 09:55:54
 */
import http from '@/utils/http';
import request from '@/utils/request';
import { QyStlBillDeType } from '@/interface/costQuotaLibrary';
//  添加工程
export function coccMtdsResource_1_constructProject(params: any): any {
  return http.post(`/cocc-mtds-resource/1/construct/project`, params);
}
//  修改工程
export function coccMtdsResource_1_constructProject_put(params: any): any {
  return http.put(`/cocc-mtds-resource/1/construct/project`, params);
}
//  新增项目空间
export function coccMtdsResource_1_projectSpace(params: any): any {
  return http.post(`/cocc-mtds-resource/1/projectSpace`, params);
}
//  修改项目空间
export function coccMtdsResource_1_constructProjectSpace_put(params: any): any {
  return http.put(`/cocc-mtds-resource/1/projectSpace`, params);
}
//  工程项目列表
export function coccMtdsResource_1_project_constructs(params: any): any {
  return http.get(`/cocc-mtds-resource/1/project/constructs`, params);
}
// 项目空间查询带分页
export function coccMtdsResource_1_projectSpace_constructs(params: any): any {
  return http.get(`/cocc-mtds-resource/1/projectSpace/page`, params);
}
// 工程项目点击编辑获取详情
export function coccMtdsResource_1_constructProject_get(
  sequenceNbr: string
): any {
  return http.get(`/cocc-mtds-resource/1/construct/project/${sequenceNbr}`);
}

// 项目空间点击编辑获取详情
export function coccMtdsResource_1_constructProjectSpace_get(
  sequenceNbr: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/construct/projectSpace/${sequenceNbr}`
  );
}

//  工程校验
export function coccMtdsResource_1_import_constructValidate(data: any): any {
  return request({
    url: '/cocc-mtds-resource/1/import/construct/validate',
    method: 'post',
    data,
  });
}

//  上传工程保存
export function coccMtdsResource_1_import_constructSave(params: any): any {
  return request({
    url: '/cocc-mtds-resource/1/import/construct/save',
    method: 'post',
    data: params,
  });
}

//  单位工程校验
export function coccMtdsResource_1_import_constructUnitValidate(
  params: any
): any {
  return http.post(
    `/cocc-mtds-resource/1/import/construct/unit/validate`,
    params
  );
}

//  单位工程保存
export function coccMtdsResource_1_import_constructUnitSave(params: any): any {
  return request({
    url: '/cocc-mtds-resource/1/import/construct/unit/save',
    method: 'post',
    data: params,
  });
}

// 导入单位工程接口
export function coccMtdsResource_1_import_toConstruct(params: any): any {
  return request({
    url: '/cocc-mtds-resource/1/import/construct/unit/save/to-constructV2',
    method: 'post',
    data: params,
  });
}

//  组价策略保存
export function coccMtdsResource_1_constructProject_bidConfig(
  params: any
): any {
  return http.post(
    `/cocc-mtds-resource/1/construct/project/bid-config`,
    params
  );
}

//  一键组价
export function coccMtdsResource_1_cocc_builder_constructMerge(
  id: string
): any {
  return request({
    url: `/cocc-mtds-resource/1/cocc/builder/construct/merge/${id}`,
    method: 'get',
  });
}

// 获取组价配置策略
export function coccMtdsResource_1_coccbuilder_functionMerge_config(
  constructId: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/construct/project/bid-config/${constructId}`
  );
}

// 获取列表
export function coccMtdsResource_1_cocc_builder_constructUnits(
  id: string
): any {
  return http.get(`/cocc-mtds-resource/1/cocc/builder/construct/units/${id}`);
}

// 获取调价页面工程目录树
export function coccMtdsResource_1_constructProject_treeBySequenceNbr(
  sequenceNbr: string
): any {
  return http.get(`/cocc-mtds-resource/1/constructProject/tree/${sequenceNbr}`);
}

// 专门为测试写的测试接口v2
export function coccMtdsResource_1_construct_mergeBySequenceNbr(
  sequenceNbr: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/cocc/builder/construct/mergev2/${sequenceNbr}`
  );
}

// 专门为测试写的测试接口v1
export function coccMtdsResource_1_construct_mergeV1BySequenceNbr(
  sequenceNbr: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/cocc/builder/construct/mergeV1/${sequenceNbr}`
  );
}

// 获取单个工程的项目总价
export function coccMtdsResource_1_constructProject_priceBySequenceNbr(
  sequenceNbr: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/constructProject/price/${sequenceNbr}`
  );
}

/**
 * 工程列表（项目空间）
 * @returns
 */
export function coccMtdsResource_1_projectSpace_getProjectSpaceList() {
  return http.get('/cocc-mtds-resource/1/projectSpace/getProjectSpaceList');
}

// 保存调价结果
export function coccMtdsResource_1_constructProject_singlePrice(
  data: any
): any {
  return request({
    url: '/cocc-mtds-resource/1/constructProject/single/price',
    method: 'PUT',
    data,
  });
}

// 提交调价结果
export function coccMtdsResource_1_constructProject_singleResult(
  params: any
): any {
  return request({
    url: '/cocc-mtds-resource/1/constructProject/single/result',
    method: 'post',
    data: params,
  });
}

// 获取调价页面保存工程目录树缓存
export function coccMtdsResource_1_constructProject_treeCacheBySequenceNbr(
  sequenceNbr: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/constructProject/treeCache/${sequenceNbr}`
  );
}

// 组价过程
export function coccSpsServer_1_construct_status_merge_data(id: string): any {
  return http.get(`/cocc-sps-server/1/construct/status/merge/data/${id}`);
}

// 判断是否可编辑项目
export function cossMtdsResource_ProjectSpace_WhetherSpace_constructId(
  constructId: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/projectSpace/whetherSpace/${constructId}`
  );
}

// 超出目标投标价之后重新输入价格
export function updateTargetBidPrice(params: any): any {
  return http.post(
    '/cocc-mtds-resource/1/constructProject/targetBidPrice',
    params
  );
}

// 超出目标投标价之后点击取消操作
export function triggerExcel(sequenceNbr: string): any {
  return http.get(
    `/cocc-mtds-resource/1/projectView/trigger/excel/${sequenceNbr}`
  );
}

// 升级3.0版本登录是否为首次
export function updateVersions(): any {
  return http.get('/cocc-mtds-resource/1/user/versions3');
}

// 升级3.0版本弹框确认
export function confirmUpdateVersions(): any {
  return http.get('/cocc-mtds-resource/1/user/confirmVersions3');
}

// 人材机详情消耗量详情
export function quotaInfo(
  unitSequenceNbr: string,
  quotaSequenceNbr: string,
  type: string
): any {
  return http.get(
    `/cocc-mtds-resource/1/projectView/de/detail/${unitSequenceNbr}/${quotaSequenceNbr}/${type}`
  );
}

// 生成清单接口
export function generateBidDetail(sequenceNbr: string): any {
  return http.post(
    `/cocc-mtds-resource/1/project/constructs/${sequenceNbr}/generate-biddetail`
  );
}

/**
 * 获取清单定额材料明细
 * type: 1:未组价,2：特殊清单项,3：组价推荐
 * sequenceNbr：清单id，type为3时需要
 * constructId: 工程id
 * qdType: 清单类型，type为3时需要，分部分项fbfx/单价措施djcs
 * biddingType: 投标招标类型，type为3时需要   0招标 1投标
 */
export function queryMergeQdModelList(params: {
  type: string;
  sequenceNbr?: string;
  constructId: string;
  qdType?: string;
  biddingType?: number;
}) {
  return http.post<QyStlBillDeType[]>(
    '/cocc-mtds-resource/1/coccbuilder/queryMergeQdModelList',
    params
  );
}

/**
 * 企业成本库跳工程项目重新计价
 * tbId: 投标id
 * @returns
 */
export function qydeReceive(params: {
  tbId: string;
  qdList: QyStlBillDeType[];
}) {
  return http.post('/cocc-mtds-resource/1/cocc/builder/qydeReceive', params);
}

/**
 * 组价分析
 * @param sequenceNbr 投标id
 * @returns
 */
export function mergeAnalysis(sequenceNbr: string) {
  return http.get<{
    gfee: number;
    safeFee: number;
    glflr: string;
    specialQd: string;
  }>(`/cocc-mtds-resource/1/constructProject/mergeAnalysis/${sequenceNbr}`);
}
