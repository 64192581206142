import store from '@/store';
const dictList = store.getters.dictList;
interface formRuls {
  [key: string]: string;
}
export const configform: formRuls = {};
console.log('dictList', dictList);
// 正整数必填
const btcheckNum = (
  rule: [],
  value: number,
  callback: (msg?: string | Error) => unknown
): void => {
  if (!value) {
    callback(new Error('不能为空'));
    return;
  }
  const patt = new RegExp(/^[0-9]*(\.[0-9]{0,0})?$/);
  const result = patt.test(value.toString());
  if (value || value === 0) {
    if (!result) {
      callback(new Error('只能填写正整数'));
    } else {
      callback();
    }
  }
};
// 正数必填
const isDigit = (
  rule: [],
  value: number,
  callback: (msg?: string | Error) => unknown
): void => {
  if (!value) {
    callback(new Error('不能为空'));
    return;
  }
  if (isNaN(value)) {
    callback(new Error('必须为数字'));
    return;
  }
  if (+value <= 0) {
    callback(new Error('必须是正数'));
    return;
  }
  callback();
};
let zbkzj = 0;
let targetBidPrice = 0;
let generalStatus = 'general';
export const validatorZbkzj = (
  rule: [],
  value: number,
  callback: (msg?: string | Error) => unknown
): void => {
  zbkzj = value;
  if (!value || value < 0) {
    callback(new Error('请输入有效招标项目控制价'));
    return;
  }

  if (isNaN(value)) {
    callback(new Error('必须为数字'));
    return;
  }
  if (targetBidPrice && +value < +targetBidPrice) {
    callback(new Error('招标项目控制价大于或等于目标投标价'));
    return;
  }
  callback();
};
export const validatoTargetBidPrice = (
  rule: [],
  value: number,
  callback: (msg?: string | Error) => unknown
): void => {
  targetBidPrice = value;
  if (!value || value < 0) {
    callback(new Error('请输入有效目标投标价，以确保组价是有效的'));
    return;
  }

  if (isNaN(value)) {
    callback(new Error('必须为数字'));
    return;
  }
  if (zbkzj && +value > +zbkzj) {
    callback(new Error('目标投标价需要小于或等于招标项目控制价'));
    return;
  }
  callback();
};

// 组价策略基础配置
export const basicConfig = [
  {
    type: 'input',
    slot: 'zbkzj',
    placeholder: '招标项目控制价不能低于目标投标价',
    itemConfig: {
      label: '招标项目控制价',
      prop: 'zbkzj',
      search: false,
      width: '33%',
      rules: [
        {
          required: true,
          validator: validatorZbkzj,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    type: 'input',
    slot: 'targetBidPrice',
    placeholder: '目标投标价中不应该包含设备费及税金',
    itemConfig: {
      label: '目标投标价',
      prop: 'targetBidPrice',
      width: '33%',
      rules: [
        {
          required: true,
          validator: validatoTargetBidPrice,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    slot: true,
    itemConfig: {
      prop: 'blank',
      width: '33%',
      wrapperCol: {
        style: 'width:33%',
      },
    },
  },
  {
    type: 'select',
    slot: 'engineerCategory',
    itemConfig: {
      label: '工程类别',
      value: 'engineer_category3',
      width: '33%',
      prop: 'engineerCategory',
      rules: [
        {
          required: true,
          message: '请选择工程类别',
          trigger: 'change',
        },
      ],
    },
    innerConfig: {
      options: dictList.engineer_category,
      events: {
        change: (val: string) => {
          console.log(val);
        },
      },
    },
  },
  {
    type: 'select',
    itemConfig: {
      label: '建设规模',
      prop: 'floorSpace',
      width: '33%',
      value: 'less_than_10000_square_meter',
      rules: [
        {
          required: true,
          message: '请选择建设规模',
          trigger: 'blur',
        },
      ],
    },
    innerConfig: {
      options: dictList.floor_space,
      events: {
        change: (val: string) => {
          console.log(val);
        },
      },
    },
  },
  {
    type: 'select',
    itemConfig: {
      label: '市政工程造价',
      prop: 'municipalConstructionCost',
      width: '34%',
      value: 'less_than_50_million',
      rules: [
        {
          required: true,
          message: '请选择市政工程造价',
          trigger: 'blur',
        },
      ],
    },
    innerConfig: {
      options: dictList.municipal_construction_cost,
      events: {
        change: (val: string) => {
          console.log(val);
        },
      },
    },
  },
  {
    type: 'select',
    itemConfig: {
      label: '工程所在地',
      value: 'city_proper',
      width: '33%',
      prop: 'projectLocation',
      rules: [
        {
          required: true,
          message: '请选择工程所在地',
          trigger: 'blur',
        },
      ],
    },
    innerConfig: {
      options: dictList.project_location,
      events: {
        change: (val: string) => {
          console.log(val);
        },
      },
    },
  },
  {
    type: 'select',
    itemConfig: {
      label: '临街面数',
      prop: 'roadSurfaceNum',
      width: '33%',
      value: 'no_way',
      rules: [
        {
          required: true,
          message: '请选择临街道面数',
          trigger: 'blur',
        },
      ],
    },
    innerConfig: {
      options: dictList.road_surface_num,
      events: {
        change: (val: string) => {
          console.log(val);
        },
      },
    },
  },
  {
    slot: true,
    itemConfig: {
      prop: 'blank1',
      width: '33%',
      wrapperCol: {
        style: 'width:33%',
      },
    },
  },

  {
    type: 'select',
    name: 'redHeadedFile',
    itemConfig: {
      label: '人工费取费调整',
      prop: 'redHeadedFile',
      value: '130100',
      width: '33%',
      key: 'value',
      rules: [
        {
          required: true,
          message: '请选择人工费取费调整',
          trigger: 'blur',
        },
      ],
    },
    innerConfig: {
      options: [],
    },
  },
  {
    slot: true,
    itemConfig: {
      prop: 'blank2',
      width: '66%',
      wrapperCol: {
        style: 'width:33%',
      },
    },
  },
  {
    type: 'input',
    slot: 'multipleLaborCost1',
    placeholder: '(默认)可修订',
    itemConfig: {
      label: '综合一类用工',
      prop: 'multipleLaborCost1',
      width: '33%',
      rules: [
        {
          required: true,
          validator: btcheckNum,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    type: 'input',
    placeholder: '(默认)可修订',
    slot: 'multipleLaborCost2',
    itemConfig: {
      label: '综合二类用工',
      prop: 'multipleLaborCost2',
      width: '33%',
      rules: [
        {
          required: true,
          validator: btcheckNum,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    type: 'input',
    slot: 'multipleLaborCost3',
    placeholder: '(默认)可修订',
    itemConfig: {
      label: '综合三类用工',
      prop: 'multipleLaborCost3',
      width: '34%',
      rules: [
        {
          required: true,
          validator: btcheckNum,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    type: 'select',
    itemConfig: {
      label: '纳税地区',
      prop: 'taxPayArea',
      width: '33%',
      value: 'city',
      rules: [
        {
          required: true,
          message: '请选择纳税地区',
          trigger: 'change',
        },
      ],
    },
    innerConfig: {
      options: dictList.tax_pay_area,
      events: {
        change: (val: string) => {
          console.log(val);
        },
      },
    },
  },
  {
    type: 'select',
    itemConfig: {
      label: '计税方式',
      prop: 'taxCalculationMethod',
      width: '33%',
      search: true,
      value: 'general',
      rules: [
        {
          required: true,
          message: '请选择计税方式',
          trigger: 'blur',
        },
      ],
    },
    innerConfig: {
      options: dictList.tax_calculation_method,
      events: {
        change: (val: string) => {
          console.log(val);
          generalStatus = val;
        },
      },
    },
  },
  {
    type: 'input',
    slot: 'jysl',
    hidden: generalStatus === 'general',
    itemConfig: {
      label: '简易计税费率',
      width: '34%',
      prop: 'jysl',
      rules: [
        {
          required: true,
          validator: isDigit,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    type: 'input',
    slot: 'outputTaxRate',
    hidden: generalStatus === 'summary',
    itemConfig: {
      label: '销项税费率',
      width: '34%',
      prop: 'outputTaxRate',
      rules: [
        {
          required: true,
          validator: isDigit,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    type: 'input',
    slot: 'additionalTaxRate',
    hidden: generalStatus === 'summary',
    itemConfig: {
      label: '附加税费率',
      width: '33%',
      prop: 'additionalTaxRate',
      rules: [
        {
          required: true,
          validator: isDigit,
          trigger: 'blur',
        },
      ],
    },
  },
  {
    slot: true,
    itemConfig: {
      prop: 'title',
      width: '100%',
      wrapperCol: {
        style: 'width:100%',
      },
    },
  },
];
// 组价策略组价配置
export const mergeConfig = (formState, status) => {
  return [
    {
      type: 'radio',
      slot: 'priority',
      itemConfig: {
        label: '组价优先选择',
        prop: 'priority',
        width: '66%',
        value: '0',
        hidden: status === 1,
      },
      innerConfig: {
        options: dictList.road_surface_num,
        events: {
          change: (val: string) => {
            console.log(val);
          },
        },
      },
    },
    {
      slot: true,
      itemConfig: {
        prop: 'blank',
        width: '33%',
        hidden: status === 1,
        wrapperCol: {
          style: 'width:60%',
        },
      },
    },
    {
      type: 'select',
      slot: 'jgIfCount',
      itemConfig: {
        label: '甲供材是否计入总价',
        prop: 'jgIfCount',
        width: '33%',
        value: '0',
        rules: [
          {
            required: true,
            message: '请选择甲供材是否计入总价',
            trigger: 'change',
          },
        ],
      },
      innerConfig: {
        options: dictList.tax_pay_area,
        events: {
          change: (val: string) => {
            console.log(val);
          },
        },
      },
    },
    {
      type: 'select',
      placeholder: '(默认)可修订',
      slot: 'zjcsSelectItems',
      itemConfig: {
        label: '总价措施取费项',
        prop: 'zjcsSelectItems',
        value: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        options: dictList.zjcs_charge_item,
        width: '33%',
        // rules: [
        //   {
        //     required: true,
        //     message: '请选择总价措施取费项',
        //     trigger: 'blur',
        //   },
        // ],
      },
    },
    {
      type: 'checkbox',
      slot: 'zjcsSetting',
      itemConfig: {
        label: '',
        prop: 'zjcsSetting',
        width: '33%',
        // rules: [
        //   {
        //     required: true,
        //     message: '请选择总价措施取费项',
        //     trigger: 'blur',
        //   },
        // ],
      },
    },
    {
      type: 'radio',
      slot: 'gfStatus',
      itemConfig: {
        label: '规费金额调整',
        width: '50%',
        prop: 'gfStatus',
        value: 0,
        rules: [
          {
            required: true,
            validator: (
              rule: [],
              value: number,
              callback: (msg?: string | Error) => unknown
            ): void => {
              if (
                value === 1 &&
                (formState.gf === undefined || formState.gf === null)
              ) {
                callback(new Error('规费不可为空'));
              }
              if (
                value === 1 &&
                Number(Number(formState.gf) + (Number(formState.awf) || 0)) >=
                  targetBidPrice
              ) {
                callback(new Error('安文费跟规费之和需小于目标投标价'));
                return;
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
      },
    },
    {
      type: 'radio',
      slot: 'awfStatus',
      itemConfig: {
        label: '安文费金额调整',
        width: '50%',
        prop: 'awfStatus',
        value: 0,
        rules: [
          {
            required: true,
            validator: (
              rule: [],
              value: number,
              callback: (msg?: string | Error) => unknown
            ): void => {
              if (
                value === 1 &&
                (formState.awf === undefined || formState.awf === null)
              ) {
                callback(new Error('安文费不可为空'));
              }
              if (
                value === 1 &&
                Number((Number(formState.gf) || 0) + Number(formState.awf)) >=
                  targetBidPrice
              ) {
                callback(new Error('安文费跟规费之和需小于目标投标价'));
                return;
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
      },
    },
  ];
};

basicConfig.map((i) => {
  configform[i.itemConfig.prop] = i.itemConfig.value as string;
});

mergeConfig(configform, status).map((i) => {
  configform[i.itemConfig.prop] = i.itemConfig.value as string;
});
