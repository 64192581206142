









































































































































































































































import Vue from 'vue';
import {
  basicConfig,
  mergeConfig,
  configform,
  validatorZbkzj,
  validatoTargetBidPrice,
} from './group-price';
import * as api from '@/api/common';
import * as projectapi from '@/api/project';

import { coccMtdsResource_1_constructProject_bidConfig } from '@/api/project';
import { mapGetters } from 'vuex';
import GroupFormConfig from '@/views/projects/popup/group-form-config.vue';
import { constructProject_sjRate } from '@/api/common';
export default Vue.extend({
  components: {
    GroupFormConfig,
  },
  computed: {
    ...mapGetters(['projectSequenceNbr', 'userInfo']),
    isDisabled(): boolean {
      return ['edit'].includes(this.$attrs.action);
    },
    col() {
      const labelWidth = 210;
      return {
        labelCol: {
          span: 1,
          style: { width: labelWidth + 'px' },
        },
        wrapperCol: {
          span: 1,
          style: { width: `calc(100% - ${labelWidth}px)` },
        },
      };
    },
    mergeConfig(): any {
      let status = this.userInfo?.userType !== 'Enterprise' ? 1 : 0;
      return mergeConfig(this.formState, status);
    },
  },
  props: {
    showform: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showform() {
      this.showconfigform = this.showform;
      if (this.showconfigform) {
        this.find();
        this.getRedHeadedFile();
      }
    },
    'formState.redHeadedFile': {
      handler: function () {
        if (!this.suspend) {
          this.getLabourcostByCity();
        }
      },
    },
    'formState.taxPayArea': {
      handler: function () {
        if (!this.suspend) {
          this.getSjRate();
        }
      },
    },
    'formState.taxCalculationMethod': {
      handler: function (val) {
        let obj = this.basicConfig.filter(
          (x) => x.itemConfig.prop === 'additionalTaxRate'
        )[0];
        let outputObj = this.basicConfig.filter(
          (x) => x.itemConfig.prop === 'outputTaxRate'
        )[0];
        let jyslObj = this.basicConfig.filter(
          (x) => x.itemConfig.prop === 'jysl'
        )[0];
        if (val === 'summary') {
          obj.hidden = true;
          outputObj.hidden = true;
          jyslObj.hidden = false;
        } else {
          obj.hidden = false;
          outputObj.hidden = false;
          jyslObj.hidden = true;
        }
        this.getSjRate();
      },
    },
  },
  data() {
    return {
      suspend: false,
      confirmLoading: false,
      basicConfig: basicConfig,
      showconfigform: false,
      formState: configform,
      redDocumentList: [] as any[], // 人工费取费地区数据列表
    };
  },
  methods: {
    /**
     * 数值限制
     */
    numberLimit(key) {
      this.formState[key] = (this.formState[key].match(
        /\d{0,10}(\.\d{0,2}|100)?/
      ) || [''])[0];
    },
    getContainer() {
      return document.getElementById('ysf_body');
    },
    async find() {
      this.suspend = false;
      const { result } =
        await projectapi.coccMtdsResource_1_coccbuilder_functionMerge_config(
          (this.$route.query.sequenceNbr as string) || this.projectSequenceNbr
        );

      if (result.priority !== '' && result.priority !== null) {
        // 修改
        this.formState = {
          ...result,
        };
        if (!result.zjcsSelectItems) {
          this.$set(this.formState, 'zjcsSelectItems', ['空']);
        }
        if (result.taxCalculationMethod === 'summary') {
          this.$set(this.formState, 'jysl', result.additionalTaxRate);
        }
        if (!result.redHeadedFile) {
          this.$set(
            this.formState,
            'redHeadedFile',
            this.redDocumentList[0].id
          );
        }
        this.suspend = true;
        // 为了处理修改字段掉接口，影响使用，后续想办法
        setTimeout(() => {
          this.suspend = false;
        }, 1000);
      } else {
        this.suspend = false;
        this.formState.laborCostAdjustArea = result.laborCostAdjustArea;
        this.getSjRate();
        // this.getQuotaRateDictByCode();
        // this.getDefQuotaRateDict();
        this.getLabourcostByCity();
      }
    },
    /**
     * @description: 人工费调整地区修改，影响综合人工费
     * @param {*}
     * @return {*}
     */
    getLabourcostByCity() {
      console.log('人工费调整地区修改，影响综合人工费');
      if (this.formState.laborCostAdjustArea && this.showconfigform) {
        this.redDocumentList.forEach((item: any) => {
          if (item.id === this.formState.redHeadedFile) {
            this.$set(this.formState, 'multipleLaborCost1', item.oneKindFree);
            this.$set(this.formState, 'multipleLaborCost2', item.twoKindFree);
            this.$set(this.formState, 'multipleLaborCost3', item.threeKindFree);
          }
        });
      }
    },
    /**
     * @description: 销项税
     * @param {*}
     * @return {*}
     */
    getDefQuotaRateDict() {
      console.log('销项税');
      api.project_getDefQuotaRates().then((res: any) => {
        const { rate } = res.result;
        this.$set(this.formState, 'outputTaxRate', rate);
      });
    },
    /**
     * @description: 获取附加税费率
     * @param {*}
     * @return {*}
     */
    getQuotaRateDictByCode() {
      console.log('获取附加税费率');
      api
        .project_getQuotaRatesByCode(this.formState.taxPayArea)
        .then((res: any) => {
          const { rate } = res.result;
          this.$set(this.formState, 'additionalTaxRate', rate);
        });
    },
    /**
     * 获取附加税，销项税，简易计税费率
     */
    getSjRate() {
      constructProject_sjRate(
        this.formState.taxPayArea,
        this.formState.taxCalculationMethod
      ).then((res) => {
        if (res.status === 200 && res.result) {
          if (this.formState.taxCalculationMethod === 'general') {
            this.$set(this.formState, 'outputTaxRate', res.result.xxs);
            this.$set(this.formState, 'additionalTaxRate', res.result.fjs);
          } else {
            this.$set(this.formState, 'jysl', res.result.jysl);
            console.log('this.formState', this.formState);
          }
        }
      });
    },
    /**
     * @description: 获取人工费调整地区
     * @param {*}
     * @return {*}
     */
    getRedHeadedFile() {
      this.redDocumentList = JSON.parse(
        sessionStorage.getItem('redDocumentList') as string
      );
      this.basicConfig.map((i) => {
        if (i.name === 'redHeadedFile') {
          (i.innerConfig.options as any[]) =
            this.redDocumentList.map((j: any) => {
              j.label = j.applicationName;
              j.value = j.id;
              return j;
            }) || [];
        }
      });
      this.$set(this.formState, 'redHeadedFile', this.redDocumentList[0].id);
    },
    async submitForm() {
      (this.$refs.formRef as any).validate((valid) => {
        if (!valid) return;
        if (this.formState.taxCalculationMethod === 'general') {
          this.$set(
            this.formState,
            'additionalTaxRate',
            this.formState.additionalTaxRate
          );
        } else {
          this.$set(this.formState, 'additionalTaxRate', this.formState.jysl);
        }
        const postData = {
          ...this.formState,
          constructIdStr:
            this.$route.query.sequenceNbr || this.projectSequenceNbr,
          constructId: this.$route.query.sequenceNbr || this.projectSequenceNbr,
          // djHalfBase: this.formState.djHalfBase ? '1' : null,
          // yjHalfBase: this.formState.yjHalfBase ? '1' : null,
        };
        this.confirmLoading = true;
        coccMtdsResource_1_constructProject_bidConfig(postData)
          .then((res) => {
            if (res.status === 200) {
              this.$emit('success');
              this.$message.success('提交成功');
              this.close();
            }
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    },
    floatMul(a, b) {
      var c = 0,
        d = a.toString(),
        e = b.toString();
      try {
        c += d.split('.')[1].length;
      } catch (f) {
        console.log('');
      }
      try {
        c += e.split('.')[1].length;
      } catch (f) {
        console.log('');
      }
      return (
        (Number(d.replace('.', '')) * Number(e.replace('.', ''))) /
        Math.pow(10, c)
      );
    },
    close() {
      validatorZbkzj([], 0, () => '');
      validatoTargetBidPrice([], 0, () => '');
      this.$emit('update:showform', false);
    },
    goCostLibrary() {
      this.$router.push('/ysf/costQuotaLibrary/list');
    },
    selectChange(value) {
      if (value.length === 0) {
        this.$set(this.formState, 'zjcsSelectItems', ['空']);
      } else if (value[0] === '空') {
        (this.formState.zjcsSelectItems as any).splice(0, 1);
      }
    },
    awfChange(e) {
      console.log('value', e.target.value);
      if (e.target.value === 0) {
        this.formState.awf = '';
      }
    },
    gfChange(e) {
      console.log('value', e.target.value);
      if (e.target.value === 0) {
        this.formState.gf = '';
      }
    },
    proving(value) {
      if (value === 1) {
        this.formState.awf = (this.formState.awf.match(
          /\d{0,10}(\.\d{0,2}|100)?/
        ) || [''])[0];
        this.$set(this.formState, 'awfStatus', 1);
      } else {
        this.formState.gf = (this.formState.gf.match(
          /\d{0,10}(\.\d{0,2}|100)?/
        ) || [''])[0];
        this.$set(this.formState, 'gfStatus', 1);
      }
    },
    checkboxChange(e, type) {
      console.log('eeeeeeee', e, type);
      if (e.target.checked) {
        if (type === 1) {
          this.$set(this.formState, 'djHalfBase', '1');
        } else {
          this.$set(this.formState, 'yjHalfBase', '1');
        }
      } else {
        if (type === 1) {
          this.$set(this.formState, 'djHalfBase', '0');
        } else {
          this.$set(this.formState, 'yjHalfBase', '0');
        }
      }
    },
  },
});
