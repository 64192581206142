var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cols-flex"},[_vm._l((_vm.columns),function(item){return [(!item.hidden)?_c('a-form-model-item',_vm._b({key:item.itemConfig.prop,style:({
        width: item.itemConfig.width || '50%',
        display: item.itemConfig.hidden || 'block',
      }),attrs:{"props":item.itemConfig.prop}},'a-form-model-item',item.itemConfig,false),[(item.slot)?_vm._t(item.itemConfig.prop):_c(_vm.componentsMap[item.type],_vm._g(_vm._b({tag:"component",attrs:{"placeholder":item.placeholder ? item.placeholder : ''},scopedSlots:_vm._u([(item.innerConfig && item.innerConfig.slot)?{key:item.innerConfig.slot,fn:function(){return [_vm._t(item.itemConfig.prop + '_' + item.innerConfig.slot)]},proxy:true}:null],null,true),model:{value:(_vm.formState[item.itemConfig.prop]),callback:function ($$v) {_vm.$set(_vm.formState, item.itemConfig.prop, $$v)},expression:"formState[item.itemConfig.prop]"}},'component',item.innerConfig,false),
          item.innerConfig && item.innerConfig.events
            ? item.innerConfig.events
            : {}
        ))],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }